import React, { useState, useEffect } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

const Customer = ({ data }) => {
  //funcion para slider de logos clientes
  var settings = {
    slidesToShow: 1,
    infinite: false,
    initialSlide: 0,
    dots: true,
    speed: 500,
    arrows: true,
  }

  const features = [
    "Integraciones con todas las herramientas",
    "Número de agentes",
    "Chatbots",
    "Funcionalidad de mensajes masivos.",
    "Enviar mensajes interactivos",
 
  ]

  const [windowsWidth, setWindowsWidth] = useState(true)
  useEffect(() => {
    let windowWidth = window.screen.width
    if (windowWidth < 1000) {
      setWindowsWidth(false)
    } else {
      setWindowsWidth(true)
    }
  }, [])


  return (
    <section className="container-compare-whatsapp container-compare-ticker-twilio container-price-ticker">
      <p className="title-question-pages-relative">
      ¿Por qué la API de WhatsApp Business y no WhatsApp Business?
      </p>
      {windowsWidth ? (
        <div className="container-boxprice-ticker">
          <div
            className="">
            <div className="cabecera-price-ticker">
              <div>f</div>
              <div className="cabecera-price-ticker-planes">
                {data.map((elemento, i) => {
                  return (
                    <div
                      key={i}
                      className={`container-price-ticker__containerfeatures__featureslogo ${Border(
                        elemento
                      )}  `}
                    >
                     <p>{elemento.img}
                        </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <section className="container-price-ticker__containerfeatures">
            {caracteristica(features, windowsWidth)}
            <div className="container-price-ticker__containerfeatures__features">
              {data.map(elemento => {
                return (
                  <div
                    className={`container-price-ticker__containerfeatures__features-detail `}
                    key={elemento}
                  >
                    {Detail(elemento, windowsWidth)}
                  </div>
                )
              })}
            </div>
          </section>
        </div>
      ) : (
        <Slider {...settings}>
          {data.map(elemento => {
            return (
              <>
                <section
                  className={`container-price-ticker__containerfeatures`}
                  key={elemento}
                >
                  {caracteristica(features, windowsWidth)}
                  <div className="container-price-ticker__containerfeatures__features">
                    <div
                      className={`container-price-ticker__containerfeatures__features-detail`}
                    >
                      {Detail(elemento, windowsWidth)}
                    </div>
                  </div>
                </section>
              </>
            )
          })}
        </Slider>
      )}
    </section>
  )
}
export default Customer

const Border = elemento => {
  if (elemento.title === "ticker") {
    return "border-whatsapp"
  }
  else{
    return "border-whatsapp-api"
  }
}

const Detail = (elemento, windowsWidth) => {
  return (
    <div>
      {windowsWidth ? (
        ""
      ) : (
        <div className={`container-price-ticker__containerfeatures__featureslogo ${Border(
          elemento
        )}  `}>
          <p>{elemento.img}</p>    
        </div>
      )}

      {elemento.info.map((item, index) => {
        return (
          <div
            className={`container-price-ticker__containerfeatures__features-detail--info 
            ${
              index < 4 ? "container-price-ticker__containerfeatures__features-detail--info": "container-price-ticker__item-one container-price-ticker__containerfeatures__features-detail--info"
            }
            
            
            
            ${
              index % 2 === 0 ? "" : "container-price-ticker__item-blue"
            } `}
            key={index}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

const caracteristica = (features, windowsWidth) => {
  return (
    <div >
      {windowsWidth ? "" : 
              <div className="border-ref">f</div>}

      {features.map((item, index) => (
        <div
          className={`
          ${
            index < 4 ? "container-price-ticker__item": "container-price-ticker__item-one container-price-ticker__item"
          }
          
          ${
            index % 2 === 0 ? "blanco": "container-price-ticker__item-blue"
          } `}
          key={index}
        >
          <p>{item}</p>
        </div>
      ))}
    </div>
  )
}
